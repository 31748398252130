import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { encryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Ordens = () => {
  const rotina = 'OrdensDeFabricação';
  const titulo = 'Ordens de Fabricação';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { ordens, getOrdens, getLoading } = useContext(OrdensContext);
  const { control, getValues, reset } = useForm({
    defaultValues: ordens?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getOrdens({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar Ordem de Fabricação',
      icon: 'add',
      action: () => navigate('/app/Ordens/Gerar'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: ordens?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: ordens?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={ordens?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Impressão de Etiquetas',
      icon: 'sell',
      action: (itens) =>
        navigate('/app/Ordens/Etiquetas', { state: { itens } }),
    },
    {
      name: 'Registrar Produção',
      icon: 'precision_manufacturing',
      action: (itens) => {
        if (!Boolean(itens?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (itens?.some((s) => s?.tipo !== itens[0]?.tipo)) {
          return toastWarning('Selecione somente itens do mesmo tipo');
        }
        if (itens?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Selecione somente itens pendentes');
        }
        if (itens?.some((s) => Boolean(s?.aprovacao))) {
          return toastWarning('Selecione somente itens aprovados');
        }
        const type =
          itens[0]?.tipo?.toLowerCase() === 'montagem'
            ? 'desmontagem'
            : itens[0]?.tipo?.toLowerCase() === 'desmontagem'
            ? 'montagem'
            : null;
        const hash = encryptURL({ itens, type });
        navigate(`/app/Ordens/Producao/${hash}`);
      },
    },
    {
      name: 'Requisitar Materiais',
      icon: 'shopping_bag',
      action: (itens) => {
        if (!Boolean(itens?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (itens?.some((s) => s?.tipo !== itens[0]?.tipo)) {
          return toastWarning('Selecione somente itens do mesmo tipo');
        }
        if (itens?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Selecione somente itens pendentes');
        }
        if (itens?.some((s) => Boolean(s?.aprovacao))) {
          return toastWarning('Selecione somente itens aprovados');
        }
        const type = itens[0]?.tipo?.toLowerCase();
        const hash = encryptURL({ itens, type });
        navigate(`/app/Ordens/Materiais/${hash}`);
      },
    },
    {
      name: 'Gerar Nota de Remessa para Industrialização',
      icon: 'post_add',
      action: (itens) => {
        if (!Boolean(itens?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (itens?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Selecione somente itens pendentes');
        }
        window.open(
          `https://comercial.eprom2.com.br/app/Remessas/Gerar/10/22/${JSON.stringify(
            itens?.map((m) => m?.id)
          )}`
        );
      },
    },
    {
      name: 'Gerar Fatura de Remessa para Industrialização',
      icon: 'post_add',
      action: (itens) => {
        if (!Boolean(itens?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (itens?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Selecione somente itens pendentes');
        }
        window.open(
          `https://comercial.eprom2.com.br/app/Remessas/Gerar/25/22/${JSON.stringify(
            itens?.map((m) => m?.id)
          )}`
        );
      },
    },
    {
      name: 'Calcular Necessidade',
      icon: 'calculate',
      action: () => navigate('/app/Ordens/Necessidade'),
    },
  ];

  const rowOptions = [
    {
      name: 'Aprovação necessária',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) => Boolean(row?.aprovacao),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: 'Ordem de Fabricação',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({ data: { codigo: 'PROD000001', item: row?.id } }),
    },
    {
      name: 'Apontamento de Horas',
      icon: 'schedule',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_apontamento_producao) &&
        row?.status === 'PENDENTE',
      action: ({ row }) =>
        navigate(`/app/Ordens/Apontamento/${encryptURL(row)}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={ordens?.colunas} />
      <Card>
        <Grid
          grid={ordens}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={ordens} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Ordens;
